var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入用户ID" },
            model: {
              value: _vm.listQuery.userId,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "userId", $$v)
              },
              expression: "listQuery.userId",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入订单编号" },
            model: {
              value: _vm.listQuery.orderSn,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "orderSn", $$v)
              },
              expression: "listQuery.orderSn",
            },
          }),
          _vm._v(" "),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "datetimerange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.listQuery.timeArray,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "timeArray", $$v)
              },
              expression: "listQuery.timeArray",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["GET /admin/order/list"],
                  expression: "['GET /admin/order/list']",
                },
              ],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("查找")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleDownload },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "全部", name: "0" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "待发货", name: "1" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "已发货", name: "2" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "已完成", name: "3" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "已关闭", name: "4" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "订单编号",
              prop: "orderSn",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户ID", prop: "accountId" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "addTime" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户昵称", prop: "nickname" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单金额", prop: "orderPrice" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "支付金额", prop: "actualPrice" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "支付时间", prop: "payTime" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单状态", prop: "orderStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(
                          _vm._f("orderStatusFilter")(scope.row.orderStatus)
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["GET /admin/order/detail"],
                            expression: "['GET /admin/order/detail']",
                          },
                        ],
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    scope.row.orderStatus == 102 ||
                    scope.row.orderStatus == 103 ||
                    scope.row.orderStatus == 203 ||
                    scope.row.orderStatus == 401 ||
                    scope.row.orderStatus == 402 ||
                    scope.row.orderStatus == 403
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/order/delete"],
                                expression: "['POST /admin/order/delete']",
                              },
                            ],
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.orderStatus == 201 || scope.row.orderStatus == 302
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/order/ship"],
                                expression: "['POST /admin/order/ship']",
                              },
                            ],
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShip(scope.row)
                              },
                            },
                          },
                          [_vm._v("发货")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.shipDialogVisible, title: "发货" },
          on: {
            "update:visible": function ($event) {
              _vm.shipDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-bottom": "20px", display: "flex" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "10px 0px 5px 0px",
                    width: "150px",
                    "text-align": "center",
                    "padding-left": "20px",
                    "font-size": "14px",
                    color: "#606266",
                    "font-weight": "700",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#ff4949", "margin-right": "4px" },
                    },
                    [_vm._v("*")]
                  ),
                  _vm._v("商品列表"),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "60%" } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.goodsList,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品名称",
                          prop: "goodsName",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品规格",
                          prop: "specifications",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(scope.row.specification.join(","))
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品价格",
                          prop: "price",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品数量",
                          prop: "number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "shipForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.shipRules,
                model: _vm.shipForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司", prop: "shipChannel" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.shipForm.shipChannel,
                        callback: function ($$v) {
                          _vm.$set(_vm.shipForm, "shipChannel", $$v)
                        },
                        expression: "shipForm.shipChannel",
                      },
                    },
                    _vm._l(_vm.channels, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递编号", prop: "shipSn" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.shipForm.shipSn,
                      callback: function ($$v) {
                        _vm.$set(_vm.shipForm, "shipSn", $$v)
                      },
                      expression: "shipForm.shipSn",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.shipDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmShip } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }