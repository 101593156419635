"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/sihong_test_front_mall/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderType = _interopRequireDefault(require("@/components/Dictionary/orderType.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MallDictionary',
  components: {
    orderType: _orderType.default
  },
  data: function data() {
    return {
      currentId: 1,
      data: [{
        label: '字典类型',
        id: 0,
        children: [{
          label: '订单类型',
          id: 1
        }, {
          label: '商品类型',
          id: 2
        }, {
          label: '认证类型',
          id: 3
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label',
        id: "id"
      }
    };
  },
  mounted: function mounted() {
    // 设置 订单类型  为默认
    this.$refs.myTree.setCurrentKey(this.data[0].children[0].id);
  },
  methods: {
    handleNodeClick: function handleNodeClick(data) {
      this.currentId = data.id;
      this.$refs.orderType.onChange(data.id);
    }
  }
};